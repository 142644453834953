import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Swal from 'sweetalert2';
import { login } from "../../Utils/endpoints";
import CustomCircularProgress from "../LodingSpiner/CustomCircularProgress";
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    setLoading(true)
    e.preventDefault();
    login({ email, password })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response.data.data.token);
          localStorage.setItem('user', JSON.stringify(response.data.data.user));
          setLoading(false)
          navigate('/');
        } else {
          console.error('Error en el inicio de sesión:', response.data.message);
          setLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Error en el inicio de sesión',
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.error('Error en el inicio de sesión:', error);
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Error en el inicio de sesión',
          text: 'Ha ocurrido un error. Por favor, intenta nuevamente.',
        });
      });
  };

  return (
    <main className="dashboard">
      <Container>
        <Card>
          <Header>
            <Title>Bienvenido de nuevo</Title>
            <Subtitle>Ingresá tu email y contraseña para acceder a tu cuenta.</Subtitle>
          </Header>
          <Form onSubmit={handleLogin}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Ingrese su email"
                required
              />
            </FormGroup>
            <FormGroup>
              <Flex>
                <Label>Contraseña</Label>
                {/* <ForgotPassword href="#">¿Olvidaste tu contraseña?</ForgotPassword> */}
              </Flex>
              <Input
                type="password"
                value={password}
                placeholder='Ingrese su contraseña'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </FormGroup>
            {
              loading ?
                <div
                
                style={{
                  width:"100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  
                }}>
                  <CustomCircularProgress

                    size={20}
                    color="#14213d"
                    padding="5px"
                  />
                </div>

                : <Button type="submit">Iniciar sesión</Button>
            }

          </Form>
        </Card>
      </Container>
    </main>
  );
};

export default Login;

// Styled components
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom right, #17223c00, #8d99ae52);
  font-family: 'Inter', sans-serif;
  animation: ${fadeIn} 1s ease-in;
`;

const Card = styled.div`
  background: #FFFFFF;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  animation: ${fadeIn} 0.5s ease-in;
`;

const Header = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #2B2D42;
`;

const Subtitle = styled.p`
  font-size: 0.875rem;
  color: #666;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #2B2D42;
`;

const Input = styled.input`
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;

  &:focus {
    border-color: #2B2D42;
    box-shadow: 0 0 0 0.1rem rgb(43 45 66);
    outline: none;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ForgotPassword = styled.a`
  font-size: 0.875rem;
  color: #007bff;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const Button = styled.button`
  height: 2.5rem;
  background: #2B2D42;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #1A202C;
  }
`;
