import { Link } from "react-router-dom";
import "./Desarrollos.scss";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { getAllDesarrollosPagination } from "../../Utils/endpoints";
import CardDesarrollo from "./CardDesarrollo";
const Desarrollos = () => {
    const [desarrollos, setDesarrollos] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const scrollContainerRef = useRef(null);

    const getAllDesarrollos = async (page) => {
        getAllDesarrollosPagination({
            page,
            perPage: 30,
        }).then((res) => {
            if (res.status === 200) {
                setDesarrollos(res.data.developments);
                setTotalPages(res.data.totalPages);
            }
        });
    };

    useEffect(() => {
        getAllDesarrollos(page);
    }, [page]);

    // eslint-disable-next-line no-unused-vars
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);

            scrollContainerRef.current.scrollTop = 0;
        }
    };

    return (
        <section className='desarrollos'>
            <h1>Desarrollos</h1>

            <div className='section-header'>
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>
                    <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z'
                            fill='#A3A9B6'
                        />
                    </svg>
                    <p>Desarrollos</p>
                </article>
                <article className='actions-page'>
                    <Link to={"/desarrollos/administrar-desarrollo"} className='main-btn'>
                        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z'
                                fill='#fcf4e6'
                            />
                        </svg>
                        Agregar Desarrollo
                    </Link>
                </article>
            </div>
            <table className='desarrollos-list'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Logo</th>
                        <th>N° de Lotes</th>

                        <th>Acciones</th>
                    </tr>
                </thead>
            </table>

            <ScrollContainer ref={scrollContainerRef}>
                {desarrollos?.length > 0 ? (
                    desarrollos.map((desarrollo, index) => {
                        if (!desarrollo.active) return <></>;
                        return <CardDesarrollo index={index} desarrollo={desarrollo} page={page} getAllDesarrollos={getAllDesarrollos} />;
                    })
                ) : (
                    <p>No hay subastas.</p>
                )}
            </ScrollContainer>

            <h2>Desarrollos desactivados</h2>

            <ScrollContainer ref={scrollContainerRef}>
                {desarrollos?.length > 0 ? (
                    desarrollos.map((desarrollo, index) => {
                        if (desarrollo.active) return <></>;
                        return <CardDesarrollo index={index} desarrollo={desarrollo} page={page} getAllDesarrollos={getAllDesarrollos} />;
                    })
                ) : (
                    <p>No hay subastas.</p>
                )}
            </ScrollContainer>
        </section>
    );
};
const ScrollContainer = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: 70vh;
    background-color: #ffffff;
    border: solid 1.5px #e0e2e7;
    /* border-radius: 8px; */
    //min-height: 70vh;
`;
export default Desarrollos;
