import { useState, useEffect } from "react";
import styled from "styled-components";

import { Popup } from "../../../Components/Popup/Popup";
import { createService } from "../../../Utils/endpoints";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    max-width: 100%;
`;

const FeatureButton = styled.button`
    background-color: ${({ selected }) => (selected ? "#17223c" : "#f8f9fa")};
    color: ${({ selected }) => (selected ? "#fff" : "#343a40")};
    border: 2px solid #343a40;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;

    &:hover {
        background-color: ${({ selected }) => (selected ? "#495057" : "#e9ecef")};
        color: ${({ selected }) => (selected ? "#fff" : "#343a40")};
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
    }
`;

const CreateServiceButton = styled.button`
    color: "#fff";
    border: 2px solid #343a40;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
`;

const ConfirmButton = styled.button`
    display: block;
    max-width: 100%;
    min-height: 34px;
    background-color: #17223c;
    border: none;
    border-radius: 7px;
    outline: none !important;
    font-size: 12px;
    font-weight: 400;
    color: #fcf4e6;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
    margin-top: 10px;
    padding: 10px 14px;

    &:hover {
        cursor: pointer;
        background-color: #0c84f3;
    }
`;

const FeaturesCheckboxes = ({ selectServices, setSelectServices, features = [], getServices }) => {
    const [open, setOpen] = useState(false);
    const [serviceName, setServiceName] = useState("");

    const toggleFeature = (name) => {
        if (selectServices.includes(name)) {
            setSelectServices((prev) => prev.filter((e) => e !== name));
        } else setSelectServices((prev) => [...prev, name]);
    };

    const handleCreateService = async () => {
        await createService(serviceName);
        setOpen(false);
        getServices();
    };

    useEffect(() => {
        setServiceName("");
    }, [open]);

    return (
        <>
            <Container id='body'>
                {features.length !== 0 &&
                    features.map((feature) => (
                        <FeatureButton
                            type='button'
                            key={feature.id}
                            selected={selectServices.includes(feature.name)}
                            onClick={() => toggleFeature(feature.name)}
                        >
                            {feature.name}
                        </FeatureButton>
                    ))}

                <CreateServiceButton type='button' onClick={() => setOpen(true)}>
                    +
                </CreateServiceButton>
            </Container>

            <Popup open={open} setOpen={setOpen}>
                <section className='crear-editar-desarrollo' style={{ width: "500px" }}>
                    <form>
                        <article className='form-col'>
                            <div className='card-input'>
                                <h4>Crear servicio</h4>

                                <div className='input-label'>
                                    <input
                                        type='text'
                                        name='name'
                                        id='name'
                                        value={serviceName}
                                        onChange={(e) => setServiceName(e.target.value)}
                                        placeholder='Nombre'
                                        required
                                    />

                                    <ConfirmButton type='button' onClick={handleCreateService}>
                                        Crear
                                    </ConfirmButton>
                                </div>
                            </div>
                        </article>
                    </form>
                </section>
            </Popup>
        </>
    );
};

export default FeaturesCheckboxes;
