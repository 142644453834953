"use client";

import { useRef, useEffect } from "react";
import ReactDOM from "react-dom";

import style from "./Popup.module.scss";

export const Popup = ({ open, setOpen, children, ...props }) => {
    const popupRef = useRef(null);

    const handleClosePopup = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("pd-scroll");

            setOpen(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Escape") {
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("pd-scroll");
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.body.classList.add("no-scroll");
            document.body.classList.add("pd-scroll");
            document.addEventListener("mousedown", handleClosePopup);
            document.addEventListener("keydown", handleKeyPress);
        } else {
            document.removeEventListener("mousedown", handleClosePopup);
            document.removeEventListener("keydown", handleKeyPress);
        }

        return () => {
            document.removeEventListener("mousedown", handleClosePopup);
            document.removeEventListener("keydown", handleKeyPress);
            document.body.classList.remove("no-scroll");
            document.body.classList.remove("pd-scroll");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return ReactDOM.createPortal(
        <div className={`popup ${style.container} ${open ? style.opened : ""}`} {...props}>
            <span ref={popupRef}>{children}</span>
        </div>,
        document.getElementById("root")
    );
};
