import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './UserDetails.css';

const CardUser = ({ data }) => {
    const navigate = useNavigate();

    const redirectToDetail = (id) => {
        navigate(`/usuarios/${id}`);
    }

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const initial = data.name ? data.name.charAt(0).toUpperCase() : '?';
    const backgroundColor = getRandomColor();

    return (
        <Container onClick={() => redirectToDetail(data.id)}>
            <ImageProfile backgroundColor={backgroundColor}>
                {initial}
            </ImageProfile>
            <NombreApellido>
                {data.name} {data.last_name}
            </NombreApellido>
            <NombreApellido>
                {data.cuit}
            </NombreApellido>
            <NombreApellido>
                {data.email}
            </NombreApellido>
            <NombreApellido>
                {data.cellphone}
            </NombreApellido>
            <NombreApellido>
                {new Date(data.last_login).toLocaleDateString()}
            </NombreApellido>
            <NombreApellido>
                {data.ban ? "Banned" : "Active"}
            </NombreApellido>
        </Container>
    )
};

const NombreApellido = styled.div`
    color: var(--Neutral-Black-Black-800, #1A1C21);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
`;

const Container = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: grid;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    grid-template-columns: auto 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
    min-height: 50px;
    padding: 16px;
    border-bottom: solid 1.5px #e0e2e7;
    justify-items: stretch;
    text-decoration: none;
    transition: background-color .3s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: #f1f2f3;
    }
`;

const ImageProfile = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: ${props => props.backgroundColor};
        font-family: Inter;
`;

const ScrollContainer = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: 70vh;
    background-color: #ffffff;
    border: solid 1.5px #e0e2e7;
    border-radius: 8px;
    min-height: 60vh;
`;

export default CardUser;
