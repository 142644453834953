import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Tareas.scss';

const Tareas = () => {
    const [popupState, setPopupState] = useState(false);

    const popupHandler = (value) => {
        setPopupState(value);
    }

    return (
        <section className='tareas'>
            <div className="section-header">
                <article className="navigate-page">
                    <Link to={'/'}>Dashboard</Link>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z" fill="#A3A9B6" />
                    </svg>
                    <p>Tareas</p>
                </article>
                {/* <article className="actions-page">
                    <Link to={'/desarrollos/administrar-desarrollo'} className='main-btn'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z" fill="#fcf4e6" />
                        </svg>
                        Agregar Desarrollo
                    </Link>
                </article> */}
            </div>

            <div className="list-container">
                <table className="tareas-list">
                    <thead>
                        <tr>
                            <th>Tarea</th>
                            <th>Usuario</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>#150 - Contactar al ganador de la subasta #54</td>
                            <td>ENZO VAZQUEZ</td>
                            <td><a href="tel:+542213554594">221 355-4594</a></td>
                            <td><a href="mailto:email@subastasdesarrollos.com">email@subastasdesarrollos.com</a></td>
                            <td><span className='pendiente'>Pendiente</span></td>
                            <td>
                                <div className="btn-holder">
                                    <button type="button" onClick={() => popupHandler(true)}>Ver Detalle</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>#150 - Contactar al ganador de la subasta #54</td>
                            <td>ENZO VAZQUEZ</td>
                            <td><a href="tel:+542213554594">221 355-4594</a></td>
                            <td><a href="mailto:email@subastasdesarrollos.com">email@subastasdesarrollos.com</a></td>
                            <td><span className='completada'>Completada</span></td>
                            <td>
                                <div className="btn-holder">
                                    <button type="button" onClick={() => popupHandler(true)}>Ver Detalle</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>

            <article className={`popup-details ${popupState ? 'popup-active' : ''}`}>
                <div className="popup-content" onClick={() => popupHandler(false)}>
                    <button type="button" className='close-btn'>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#17223c">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <circle cx="12" cy="12" r="10" stroke="#17223c" strokeWidth="1.5"></circle>
                                <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#17223c" strokeWidth="1.5" strokeLinecap="round"></path>
                            </g>
                        </svg>
                    </button>
                    <h2>Detalle de la tarea</h2>

                    <p>Descripción de la tarea</p>

                    <button type='button' className='change-state-btn'>Tarea Completada</button>
                </div>
            </article>
        </section>
    );
}

export default Tareas;