import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import './RadarChart.css'; // Asegúrate de tener un archivo CSS para los estilos

const RadarChart = () => {
  const data = [
    { name: 'Actual', value: 16000 },  // Valor actual en dólares
    { name: 'Restante', value: 20000 - 16000 },  // Diferencia hasta el objetivo
  ];

  const COLORS = ['#14213d', '#d3d3d3'];

  return (
    <div className="sales-progress-container">
      <h2>Actividad</h2>
      <PieChart style={{ width: '100%' }} width={200} height={200}>
        <Pie
          data={data}
          cx={100}
          cy={100}
          startAngle={180}
          endAngle={0}
          innerRadius={70}
          outerRadius={90}
          fill="#14213d"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
      </PieChart>

      <p>Haz logrado $240 hoy, es más alto que ayer</p>
      <div className="targets">
        <div>
          <p>Objetivo</p>
          <p>$20k</p>
        </div>
        <div>
          <p>Actual</p>
          <p>$16k</p>
        </div>
      </div>
    </div>
  );
};

export default RadarChart;
