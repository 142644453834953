import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './UserDetails.css';
import { useLocation, useParams } from "react-router-dom";
import { getUserById } from "../../Utils/endpoints"
import { PublicUrl } from "../../Utils/config"
import { formatDateTime, formatNumber } from "../../Utils/utilsfunctions";
import styled from 'styled-components';
const UserDetails = () => {
  const [selectUser, setSelectUser] = useState()
  const params = useParams();
  const [user, setUser] = useState()


  const getUser = async (id) => {
    getUserById({
      id: id
    }).then((res) => {
      if (res.status === 200) {
        setUser(res.data)
        // console.log(res.data)
      }
    })
  }
  useEffect(() => {
    console.log(params.id)
    getUser(params.id)
  }, [params])
  useEffect(() => {
    console.log(user)
    // getActuion(params.id)
  }, [user])


  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const initial = user?.name ? user?.name.charAt(0).toUpperCase() : '?';
  const backgroundColor = getRandomColor();
  return (
    <>
      {
        user?.id ?
          <h1 style={{ marginLeft: 24, marginRight: 24 }}>{user.name} {user.last_name}</h1>

          : null
      }

      <div className="section-header">
        <article className="navigate-page" style={{ margin: '0 24px' }}>
          <Link to={'/'}>Dashboard</Link>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z" fill="#A3A9B6" />
          </svg>
          <Link to={'/usuarios'}>Usuarios</Link>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z" fill="#A3A9B6" />
          </svg>
          {
            user?.id ?
              <p>{user.name} {user.last_name}</p>
              : null
          }
        </article>
        {/* <article className="actions-page">
                    <Link to={'/desarrollos/administrar-desarrollo'} className='main-btn'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z" fill="#fcf4e6" />
                        </svg>
                        Agregar Desarrollo
                    </Link>
                </article> */}
      </div>
      {
        user?.id ?
          <div className="user-details-container">

            <div className="user-info">
              <ImageProfile backgroundColor={backgroundColor}>
                {initial}
              </ImageProfile>
              <h2>{user.name} {user.last_name}</h2>
              <p className="verified">{user.id_verified ? "Verificado" : "Sin verificar"}</p>
              <p>ID: {user.id}</p>
              <div className="user-info-details">
                <div className="info-item">
                  <i className="icon"></i>
                  <span><b>Nombre de Usuario:</b></span>
                  <span className="info-text">{user.nickname}</span>
                </div>
                <div className="info-item">
                  <i className="icon"></i>
                  <span><b>Email:</b></span>
                  <span className="info-text">{user.email}</span>
                </div>
                <div className="info-item">
                  <i className="icon"></i>
                  <span><b>Teléfono:</b></span>
                  <span className="info-text">{user.cellphone}</span>
                </div>
                <div className="info-item">
                  <i className="icon"></i>
                  <span><b>Domicilio:</b></span>
                  <span className="info-text">{user.address}</span>
                </div>

                <div className="info-item">
                  <i className="icon"></i>
                  <span><b>Tipo de Usuario:</b></span>
                  <span className="info-text">{user.role}</span>
                </div>
              </div>
            </div>
            <div className="bidding-history">
              <div className="bidding-header">
                <h2>Historial de Pujas</h2>
                {/* <button className="period-button">Seleccionar período</button> */}
              </div>
              <table className="bidding-table">
                <thead>
                  <tr>
                    <th>Subasta</th>
                    <th>Valor</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.bidders?.map((bid, index) => (
                    <tr key={bid.id}>
                      <td>
                        <a href={`${PublicUrl}/subastas/subasta/${bid.auction.id}`}>
                        {bid.auction.lot?.development?.name} - Lote #{bid.auction.lot.id}
                        </a>
                      </td>
                      <td>USD {formatNumber(bid?.bid_num)}</td>
                      <td>{formatDateTime(bid.updatedAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </div >
          : null
      }


    </>
  )

};
const ImageProfile = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    color: white;
    font-weight: bold;
    font-size: 32px;
    background-color: #019A90;
    font-family: Inter;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-bottom: 16px;
`;
export default UserDetails;
