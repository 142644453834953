import axios from "axios";
import { BaseUrl } from "./config";

export const getAllUsersPagination = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.get(`${BaseUrl}users/getAllUsersPagination`, {
            params: data, // Pasar data como parámetro de consulta
        });

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;

        // throw error; // Re-lanzar el error para que el controlador o middleware lo maneje
    }
};

export const getAllSubastasPagination = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.get(`${BaseUrl}auctions/getAllAuctionPagination`, {
            params: data, // Pasar data como parámetro de consulta
        });

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;

        // throw error; // Re-lanzar el error para que el controlador o middleware lo maneje
    }
};
export const getAuctionById = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.get(`${BaseUrl}auctions/getAuctionById`, {
            params: data, // Pasar data como parámetro de consulta
        });

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;

        // throw error; // Re-lanzar el error para que el controlador o middleware lo maneje
    }
};
export const getDevelopmentById = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.get(`${BaseUrl}developments/getDevelopmentById`, {
            params: data, // Pasar data como parámetro de consulta
        });

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;

        // throw error; // Re-lanzar el error para que el controlador o middleware lo maneje
    }
};

export const getUserById = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.get(`${BaseUrl}users/getUserById`, {
            params: data, // Pasar data como parámetro de consulta
        });

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;

        // throw error; // Re-lanzar el error para que el controlador o middleware lo maneje
    }
};

export const login = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.post(`${BaseUrl}users/loginDashboard`, data);

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;

        // throw error; // Re-lanzar el error para que el controlador o middleware lo maneje
    }
};

export const uploadToS3 = async (formData) => {
    try {
        const response = await axios.post(`${BaseUrl}aws/uploadPhoto`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const createDesarrollo = async (data) => {
    try {
        const response = await axios.post(`${BaseUrl}developments/dashboard/create`, data);

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const getAllServices = async () => {
    try {
        const authToken = localStorage.getItem("token");

        const response = await axios.get(`${BaseUrl}services`, { headers: { Authorization: `Bearer ${authToken}` } });

        return response.data;
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const createService = async (data) => {
    try {
        const authToken = localStorage.getItem("token");

        const response = await axios.post(
            `${BaseUrl}service/create`,
            { name: data },
            { headers: { Authorization: `Bearer ${authToken}` } }
        );
        return response;
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const deactivateDevelopment = async (developmentId, status) => {
    try {
        const authToken = localStorage.getItem("token");

        const response = await axios.put(
            `${BaseUrl}development/deactivate/${developmentId}`,
            { status },
            {
                headers: { Authorization: `Bearer ${authToken}` },
            }
        );

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const getAllDesarrollosPagination = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.get(`${BaseUrl}developments/getAllDesarrollosPagination`, {
            params: data, // Pasar data como parámetro de consulta
        });

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;

        // throw error; // Re-lanzar el error para que el controlador o middleware lo maneje
    }
};

export const createAuction = async (data) => {
    try {
        // Obtener el token de acceso
        console.log(data, "ENDPOINTS");
        const response = await axios.post(`${BaseUrl}auctions/createAuction`, data);

        if (response?.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const auctionsDevelopment = async (developmentId) => {
    try {
        const authToken = localStorage.getItem("token");

        const response = await axios.get(`${BaseUrl}auctions/active/${developmentId}`, {
            headers: { Authorization: `Bearer ${authToken}` },
        });

        return response.data;
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const updateGalleryDevelopment = async (data, developmentId) => {
    try {
        const authToken = localStorage.getItem("token");

        const response = await axios.put(
            `${BaseUrl}development/gallery`,
            { gallery: data, id: developmentId },
            { headers: { Authorization: `Bearer ${authToken}` } }
        );

        return response.data;
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};

export const updateDevelopment = async ({ description, lots, developmentId, documentation, imageDevelopmentId }) => {
    try {
        const authToken = localStorage.getItem("token");

        if (!documentation) {
            await axios.put(
                `${BaseUrl}development`,
                { description, lots, developmentId },
                { headers: { Authorization: `Bearer ${authToken}` } }
            );

            return true;
        }

        const formData = new FormData();
        formData.append("file", documentation);

        const responseAws = await axios.post(`${BaseUrl}aws/uploadPhoto`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (responseAws?.status === 200) {
            await axios.put(
                `${BaseUrl}development`,
                { description, lots, developmentId, urlDocumentation: responseAws.data.photoUrl, imageDevelopmentId },
                { headers: { Authorization: `Bearer ${authToken}` } }
            );

            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        return error.response;
    }
};
