import { MapContainer, ImageOverlay, FeatureGroup, TileLayer } from "react-leaflet";
import React, { useEffect, useState, useRef } from "react";
import { EditControl } from "react-leaflet-draw";
import L, { CRS } from "leaflet";

import style from "./EditInteractiveMap.module.scss";
import { Icon_close as IconClose } from "../../svgs";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

const EditInteractiveMap = ({ imageFile, lots, auctionActives }) => {
    const featureGroupRef = useRef();
    const mapRef = useRef();

    const [center, setCenter] = useState([0, 0]);
    const [mapReady, setMapReady] = useState(false);
    const [imageBounds, setImageBounds] = useState(null);
    const [activeLayer, setActiveLayer] = useState(null);

    const [selectedLot, setSelectedLot] = useState(null);
    const [selectedAuction, setSelectedAuction] = useState(null);

    useEffect(() => {
        const image = new Image();
        image.src = imageFile;

        image.onload = function () {
            const imageWidth = image.width;
            const imageHeight = image.height;

            const southWest = mapRef.current.unproject([0, imageHeight], mapRef.current.getMaxZoom());
            const northEast = mapRef.current.unproject([imageWidth, 0], mapRef.current.getMaxZoom());

            const centerLat = (southWest.lat + northEast.lat) / 2;
            const centerLng = (southWest.lng + northEast.lng) / 2;
            setCenter([centerLat, centerLng]);

            const bounds = L.latLngBounds(southWest, northEast);
            setImageBounds(bounds);
        };

        return () => {
            image.onload = null;
        };
    }, [imageFile]);

    const loadLots = () => {
        if (featureGroupRef.current) {
            const layerGroup = featureGroupRef.current;

            const defaultLots = lots.filter((lot) => !auctionActives.some((auction) => auction.lot.number_lot === lot.number_lot));

            auctionActives.forEach((auction, i) => {
                if (auction.lot.coordinates) {
                    const coordinates = auction.lot.coordinates.flat(1).map(([x, y]) => {
                        return mapRef.current.unproject([x, y]);
                    });

                    let color = "#023e7d";

                    const polygon = L.polygon(coordinates, {
                        color: color,
                        weight: 1,
                        stroke: 0,
                        fillOpacity: 0.3,
                    });

                    polygon.auctionData = auction;

                    polygon.on({
                        click: () => {
                            setSelectedAuction(polygon.auctionData);
                        },
                        dblclick: () => {
                            handlePolygonClick(polygon);
                        },
                    });

                    layerGroup.addLayer(polygon);
                }
            });

            defaultLots.forEach((lot, i) => {
                if (lot.coordinates) {
                    const coordinates = lot.coordinates.flat(1).map(([x, y]) => {
                        return mapRef.current.unproject([x, y]);
                    });

                    let color = lot.selled === "Disponible" ? "#B8D368" : lot.selled === "Reservado" ? "#FC7032" : "#FC7032";

                    const polygon = L.polygon(coordinates, {
                        color: color,
                        weight: 1,
                        stroke: 0,
                        fillOpacity: 0.3,
                    });

                    polygon.lotData = lot;

                    polygon.on({
                        click: () => {
                            setSelectedLot(polygon.lotData);
                        },
                        dblclick: () => {
                            handlePolygonClick(polygon);
                        },
                    });

                    layerGroup.addLayer(polygon);
                }
            });
        }
    };

    // Convierte el poligono en rectangulo para poder moverlo de lugar
    const handlePolygonClick = (polygon) => {
        if (activeLayer) {
            return;
        }

        const bounds = polygon.getBounds();
        const rectangle = L.rectangle(bounds, {
            color: polygon.options.color,
            weight: 1,
            stroke: 0,
            fillOpacity: 0.3,
        });

        const layerGroup = featureGroupRef.current;
        layerGroup.removeLayer(polygon);
        layerGroup.addLayer(rectangle);

        rectangle.lotData = polygon.lotData;

        rectangle.on("edit", () => handleRectangleEdit(rectangle, polygon));
        setActiveLayer(rectangle);
    };

    const handleRectangleEdit = (rectangle, originalPolygon) => {
        const bounds = rectangle.getBounds();
        const corners = [bounds.getSouthWest(), bounds.getNorthWest(), bounds.getNorthEast(), bounds.getSouthEast()];

        const newPolygon = L.polygon(corners, {
            color: rectangle.options.color,
            weight: 1,
            stroke: 0,
            fillOpacity: 0.3,
        });

        newPolygon.lotData = originalPolygon.lotData;

        newPolygon.on({
            click: () => {
                setSelectedLot(newPolygon.lotData);
            },
            dblclick: () => {
                handlePolygonClick(newPolygon);
            },
        });

        const layerGroup = featureGroupRef.current;
        layerGroup.removeLayer(rectangle);
        layerGroup.addLayer(newPolygon);

        setActiveLayer(null);
    };

    useEffect(() => {
        loadLots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapReady]);

    const getAllPolygons = () => {
        const layerGroup = featureGroupRef.current;
        const allPolygons = [];

        layerGroup.eachLayer((layer) => {
            if (layer instanceof L.Polygon) {
                const latLngs = layer.getLatLngs()[0].map((latlng) => [latlng.lat, latlng.lng]);
                allPolygons.push({ ...layer.lotData, coordinates: latLngs });
            }
        });

        // console.log("All Polygons:", allPolygons);
        return allPolygons;
    };

    const handleSaveLot = (updatedLot) => {
        // Actualizar el lot en el estado de los lots
        setSelectedLot(updatedLot);

        // Actualizar el polígono correspondiente en el featureGroupRef
        if (featureGroupRef.current) {
            featureGroupRef.current.eachLayer((layer) => {
                if (layer instanceof L.Polygon && layer.lotData.id === updatedLot.id) {
                    // Actualizar datos del polígono
                    layer.lotData = updatedLot;

                    // Cambiar el color del poligono si se actualiza el estado de este
                    const newColor =
                        updatedLot.selled === "Disponible" ? "#B8D368" : updatedLot.selled === "Reservado" ? "#FC7032" : "#FC7032";

                    layer.setStyle({ color: newColor });
                }
            });
        }
    };

    const handleSaveAuction = (updatedAuction) => {};

    return (
        <div>
            {/* <button onClick={getAllPolygons}>Guadar</button> */}
            <MapContainer
                zoom={25}
                tap={false}
                ref={mapRef}
                center={center}
                crs={CRS.Simple}
                zoomControl={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                whenReady={() => setMapReady(true)}
                style={{ height: "60vh", width: "100%" }}
            >
                <TileLayer url='' />

                {imageBounds && <ImageOverlay url={imageFile} bounds={imageBounds} />}

                <FeatureGroup ref={featureGroupRef}>
                    <EditControl
                        position='topleft'
                        draw={{
                            rectangle: true,
                            polyline: false,
                            circle: true,
                            circlemarker: false,
                            marker: false,
                            polygon: true,
                        }}
                        edit={{
                            edit: true,
                            remove: true,
                        }}
                    />
                </FeatureGroup>

                {selectedLot && <PopupLot lot={selectedLot} setSelectedLot={setSelectedLot} onSaveLot={handleSaveLot} />}
                {selectedAuction && (
                    <PopupAuction
                        selectedAuction={selectedAuction}
                        setSelectedAuction={setSelectedAuction}
                        onSaveAuction={handleSaveAuction}
                    />
                )}
            </MapContainer>
        </div>
    );
};

export default EditInteractiveMap;

const PopupLot = ({ lot, setSelectedLot, onSaveLot }) => {
    const [measures, setMeasures] = useState("");
    const [numberLot, setNumberLot] = useState("");
    const [availability, setAvailability] = useState("");

    useEffect(() => {
        if (lot) {
            setNumberLot(lot.number_lot);
            setAvailability(lot.selled);
            setMeasures(lot.measures);
        }
    }, [lot]);

    const handleClose = () => {
        setSelectedLot(null);
    };

    const handleSave = () => {
        const updatedLot = {
            ...lot,
            number_lot: numberLot,
            measures: measures,
            selled: availability,
        };

        onSaveLot(updatedLot);
        setSelectedLot(null); // Cierra el popup después de guardar
    };

    return (
        <div className={style.popupLot}>
            <div className={style.popupLot__header}>
                <IconClose fill='#17223c' onClick={handleClose} />
            </div>

            <div className={style.popupLot__body}>
                <div className={style.popupLot__body__field}>
                    Lote número:
                    <input type='text' value={numberLot || ""} onChange={(e) => setNumberLot(e.target.value)} />
                </div>

                <div className={style.popupLot__body__field}>
                    Dimensiones:
                    <input type='text' value={measures || ""} onChange={(e) => setMeasures(e.target.value)} />
                </div>

                <div className={style.popupLot__body__field}>
                    Disponibilidad:
                    <select name='status' value={availability || ""} onChange={(e) => setAvailability(e.target.value)}>
                        <option value='Disponible'>Disponible</option>
                        <option value='Reservado'>Reservado</option>
                        <option value='Vendido'>Vendido</option>
                    </select>
                </div>

                <button onClick={handleSave}>Guardar</button>
            </div>
        </div>
    );
};

const PopupAuction = ({ selectedAuction, setSelectedAuction, onSaveAuction }) => {
    const [measures, setMeasures] = useState("");
    const [numberLot, setNumberLot] = useState("");
    const [availability, setAvailability] = useState("");

    useEffect(() => {
        if (selectedAuction) {
            // setNumberLot(lot.number_lot);
            // setAvailability(lot.selled);
            // setMeasures(lot.measures);
            // console.log(selectedAuction);
        }
    }, [selectedAuction]);

    const handleClose = () => {
        setSelectedAuction(null);
    };

    const handleSave = () => {
        const updatedLot = {
            ...selectedAuction,
            // number_lot: numberLot,
            // measures: measures,
            // selled: availability,
        };

        onSaveAuction(updatedLot);
        setSelectedAuction(null);
    };

    return (
        <div className={style.popupLot}>
            <div className={style.popupLot__header}>
                <IconClose fill='#17223c' onClick={handleClose} />
            </div>

            <div className={style.popupLot__body}>
                <div className={style.popupLot__body__field}>
                    Lote número:
                    <input type='text' value={numberLot || ""} onChange={(e) => setNumberLot(e.target.value)} />
                </div>

                <div className={style.popupLot__body__field}>
                    Dimensiones:
                    <input type='text' value={measures || ""} onChange={(e) => setMeasures(e.target.value)} />
                </div>

                <div className={style.popupLot__body__field}>
                    Disponibilidad:
                    <select name='status' value={availability || ""} onChange={(e) => setAvailability(e.target.value)}>
                        <option value='Disponible'>Disponible</option>
                        <option value='Reservado'>Reservado</option>
                        <option value='Vendido'>Vendido</option>
                    </select>
                </div>

                <button onClick={handleSave}>Guardar</button>
            </div>
        </div>
    );
};
