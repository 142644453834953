import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import style from "./PDFVisualizer.module.scss";
import "react-pdf/dist/Page/TextLayer.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker", import.meta.url).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PDFVisualizer = ({ pdf }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToPrevPage = (e) => {
        e.preventDefault();
        setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
    };

    const goToNextPage = (e) => {
        e.preventDefault();
        setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
    };

    return (
        <div className={style.container}>
            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>

            {pdf && (
                <nav className={style.container__nav}>
                    <p>
                        Pagina {pageNumber} de {numPages}
                    </p>

                    <span>
                        <button onClick={goToPrevPage}>Anterior</button>
                        <button onClick={goToNextPage}>Siguiente</button>
                    </span>
                </nav>
            )}
        </div>
    );
};
