import React from "react";
import { Oval } from "react-loader-spinner";

const CustomCircularProgress = ({ size, color = "#052d43", padding = "0px" }) => {
    return (
      <Oval
        height={size}
        width={size}
        color={color}
        wrapperStyle={{ padding: padding }}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor={color}
        strokeWidth={4}
        strokeWidthSecondary={4}
      styles={{ padding: padding,
       }}
      />
    );
  };
  
  export default CustomCircularProgress;