import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

import "./Desarrollo.scss";
import "react-quill/dist/quill.snow.css";
import { Popup } from "../../../Components/Popup/Popup";
import DragDrop from "../../../Components/DragDrop/DragDrop";
import { PDFVisualizer } from "../../../Components/PDFVisualizer/PDFVisualizer";
import { ContainerImage } from "../../../Components/ContainerImage/ContainerImage";
import LoadingSpinner from "../../../Components/LodingSpiner/CustomCircularProgress";
import EditInteractiveMap from "../../../Components/EditInteractiveMap/EditInteractiveMap";
import CustomCircularProgress from "../../../Components/LodingSpiner/CustomCircularProgress";
import { Icon_save as IconSave, Icon_plus as IconPlus, Icon_edit_documentation as IconEditDoc } from "../../../svgs";
import { getDevelopmentById, auctionsDevelopment, uploadToS3, updateGalleryDevelopment, updateDevelopment } from "../../../Utils/endpoints";

const Desarrollo = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [development, setDevelopment] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [documentFile, setDocumentFile] = useState([]);
    const [galleryImages, setGalleryImages] = useState([]);
    const [popupAddImage, setPopupAddImage] = useState(false);
    const [auctionActives, setAuctionActives] = useState(null);
    const [descriptionValue, setDescriptionValue] = useState(null);
    const [popupEditDocumentation, setPopupEditDocumentation] = useState(false);

    const [updatedGallery, setUpdatedGallery] = useState([]);

    const getDevelopment = async (id) => {
        getDevelopmentById({
            id: id,
        }).then((res) => {
            if (res.status === 200) {
                setDevelopment(res.data);
                setDescriptionValue(res.data?.description);
                setGalleryImages(res.data?.imageDevelopment?.gallery);
                setDocumentFile({ fileURL: res.data.imageDevelopment?.documentation });
            }
        });
    };

    console.log(development);

    const getAuctionsDevelopment = async (developmentId) => {
        auctionsDevelopment(developmentId).then((response) => {
            setAuctionActives(response.data);
        });
    };

    useEffect(() => {
        getDevelopment(params?.id);
        getAuctionsDevelopment(params?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const handlePopupAddImage = () => {
        setPopupAddImage(true);
    };

    const handlePopupDocumentation = () => {
        setPopupEditDocumentation(true);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        let result = await updateDevelopment({
            description: descriptionValue,
            lots: [],
            documentation: documentFile.file,
            developmentId: development.id,
            imageDevelopmentId: development.imageDevelopment.id,
        });

        if (updatedGallery !== galleryImages && updatedGallery.length !== 0) {
            await updateGalleryDevelopment(updatedGallery, development.imageDevelopment.id).then((response) => {
                console.log(response);
            });
        }

        if (result) {
            Swal.fire("Actualizado", "El desarrollo ha sido actualizado con éxito.", "success");
        } else {
            Swal.fire("Error", "Ha sucedido un error al intentar actualizar el desarrollo.", "error");
        }
        setIsLoading(false);
        navigate("/desarrollos");
    };

    if (!development)
        return (
            <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <LoadingSpinner />
            </div>
        );

    return (
        <>
            <section className='desarrollo'>
                <img src={development.imageDevelopment.logo} alt='La Escondida' width={320} height={160} loading='lazy' decoding='async' />

                <div className='section-header'>
                    <article className='navigate-page'>
                        <Link to={"/"}>Dashboard</Link>

                        <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z'
                                fill='#A3A9B6'
                            />
                        </svg>

                        <Link to={"/desarrollos"}>Desarrollos</Link>

                        <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z'
                                fill='#A3A9B6'
                            />
                        </svg>
                        <p>{development.name}</p>
                    </article>
                    <article className='actions-page'>
                        {isLoading ? (
                            <div
                                style={{ width: "109px", height: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <LoadingSpinner size={20} color='#14213d' padding='5px' />
                            </div>
                        ) : (
                            <button className='main-btn' onClick={handleSubmit}>
                                <IconSave fill='#fcf4e6' width='15' height='15' />
                                Guardar
                            </button>
                        )}

                        <Link to={"/desarrollos/desarrollo/id/administrar-lote"} className='main-btn'>
                            <IconPlus />
                            Agregar Lote
                        </Link>
                    </article>
                </div>

                <div className='section-body-developments'>
                    <div className='input-label'>
                        <ReactQuill theme='snow' value={descriptionValue} onChange={setDescriptionValue} />
                    </div>

                    <div className='section-body-developments__gallery'>
                        <h3>Galería</h3>

                        <div className='section-body-developments__gallery__content'>
                            <ContainerImage
                                handlePopupAddImage={handlePopupAddImage}
                                setUpdatedGallery={setUpdatedGallery}
                                galleryImages={galleryImages}
                            />
                        </div>
                    </div>

                    <article className='edit-lots-development'>
                        <h3 style={{ marginBottom: 10 }}>Loteo</h3>

                        <div className='leyendas-desarrollo'>
                            <div className='leyendas-desarrollo__disponibles'>
                                <div /> <p>Disponible</p>
                            </div>
                            <div className='leyendas-desarrollo__subastas_activas'>
                                <div /> <p>Subasta activa</p>
                            </div>
                            <div className='leyendas-desarrollo__vendidos'>
                                <div /> <p>Vendido</p>
                            </div>
                        </div>

                        {development?.id && auctionActives ? (
                            <EditInteractiveMap
                                imageFile={development.imageDevelopment.desarrollo_plane}
                                auctionActives={auctionActives}
                                lots={development.lots}
                                containerHeight='100%'
                            />
                        ) : null}
                    </article>

                    <article className='edit-pdf-documentation'>
                        <div className='edit-pdf-documentation__header'>
                            <h3>Documentación</h3>
                            <span onClick={handlePopupDocumentation}>
                                <p>Cambiar</p>
                                <IconEditDoc width='20' height='20' stroke='#fcf4e6' />
                            </span>
                        </div>

                        <PDFVisualizer pdf={documentFile?.fileURL} />
                    </article>
                </div>
            </section>

            <PopupDragDrop
                open={popupAddImage}
                development={development}
                setOpen={setPopupAddImage}
                galleryImages={galleryImages}
                setGalleryImages={setGalleryImages}
            />

            <PopupDocumentation
                documentFile={documentFile}
                open={popupEditDocumentation}
                setDocumentFile={setDocumentFile}
                setOpen={setPopupEditDocumentation}
            />
        </>
    );
};

export default Desarrollo;

const PopupDragDrop = ({ open, setOpen, development, galleryImages, setGalleryImages }) => {
    const [galleryFiles, setGalleryFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const uploadNewImages = async () => {
        if (galleryFiles.length === 0) return;
        setIsLoading(true);

        const uploadPromises = [];
        const galleryFilesUrls = [];

        for (let file of galleryFiles) {
            const formData = new FormData();
            formData.append("file", file);
            uploadPromises.push(
                uploadToS3(formData).then((response) => {
                    if (response.status === 200) {
                        galleryFilesUrls.push(response.data.photoUrl);
                    }
                })
            );
        }

        await Promise.all(uploadPromises);

        let uploadedImages = [...galleryImages, ...galleryFilesUrls];
        setGalleryImages((prev) => [...prev, ...galleryFilesUrls]);

        updateGalleryDevelopment(uploadedImages, development.imageDevelopment.id).then(() => {
            setIsLoading(false);
        });

        setOpen(false);
    };

    useEffect(() => {
        return () => {
            setGalleryFiles([]);
        };
    }, [open]);

    return (
        <Popup open={open} setOpen={setOpen}>
            <div className='container-add-image-popup'>
                <DragDrop
                    files={galleryFiles}
                    setFiles={setGalleryFiles}
                    isMultiple={true}
                    text={"Arrastrá y soltá las imágenes"}
                    accept={"image/png, image/jpeg, image/webp"}
                    name='galeria'
                />
            </div>

            {isLoading ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <CustomCircularProgress size={20} color='#14213d' padding='5px' />
                </div>
            ) : (
                <button className='container-add-image-button' onClick={uploadNewImages}>
                    Subir imagenes
                </button>
            )}
        </Popup>
    );
};

const PopupDocumentation = ({ open, setOpen, setDocumentFile }) => {
    const [file, setFile] = useState([]);

    useEffect(() => {
        return () => {
            setFile([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleSave = (e) => {
        e.preventDefault();

        if (file.length === 0) return null;
        const selectedFile = file[0];
        let fileURL = URL.createObjectURL(file[0]);

        setDocumentFile({ file: selectedFile, fileURL: fileURL });
        setOpen(false);
    };

    return (
        <Popup open={open} setOpen={setOpen}>
            <div className='container-add-documentation-popup'>
                <DragDrop
                    files={file}
                    setFiles={setFile}
                    text={"Arrastrá y soltá el documento"}
                    accept={"application/pdf"}
                    name='documentacion'
                />
            </div>

            <button className='container-add-image-button' onClick={handleSave}>
                Confirmar
            </button>
        </Popup>
    );
};
