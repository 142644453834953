import { useRef, useState } from "react";

import { PDFVisualizer } from "../PDFVisualizer/PDFVisualizer";
import "./DragDrop.scss";

const DragDrop = ({ files = [], setFiles, isMultiple = false, text = "Arrastrá y soltá el archivo", accept = "*", name }) => {
    const [dragActive, setDragActive] = useState(false);
    const [isPdf, setIsPdf] = useState(false);
    const inputRef = useRef(null);

    const handleFiles = (fileList) => {
        const newFiles = Array.from(fileList);
        if (newFiles[0].type === "application/pdf") {
            setIsPdf(true);
        }

        setFiles((prevState) => (Array.isArray(prevState) ? [...prevState, ...newFiles] : newFiles));
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files) {
            handleFiles(e.target.files);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const removeFile = (idx) => {
        const newArr = files.filter((_, index) => index !== idx);
        setFiles(newArr);
    };

    const openFileExplorer = () => {
        inputRef.current.value = "";
        inputRef.current.click();
    };

    return (
        <div className='drag-drop-component'>
            <article
                className={`drag-drop ${dragActive ? "drag-drop-active" : ""}`}
                onDragEnter={!isMultiple && files.length > 0 ? null : handleDragEnter}
                onDrop={!isMultiple && files.length > 0 ? null : handleDrop}
                onDragLeave={!isMultiple && files.length > 0 ? null : handleDragLeave}
                onDragOver={!isMultiple && files.length > 0 ? null : handleDragOver}
            >
                {files.length > 0 && !isMultiple ? (
                    <div className='images-preview'>
                        {!isPdf
                            ? files.map((file, index) => (
                                  <div key={index} className='image-container'>
                                      <img src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
                                      <button type='button' onClick={() => removeFile(index)}>
                                          Eliminar
                                      </button>
                                  </div>
                              ))
                            : files.map((file, index) => (
                                  <div key={index} className='pdf-container'>
                                      <PDFVisualizer pdf={URL.createObjectURL(file)} />
                                      <button type='button' onClick={() => removeFile(index)}>
                                          Eliminar
                                      </button>
                                  </div>
                              ))}
                    </div>
                ) : (
                    <>
                        <input
                            className='hidden'
                            ref={inputRef}
                            type='file'
                            multiple={isMultiple}
                            onChange={handleChange}
                            accept={accept}
                            name={name}
                        />

                        <svg width='55' height='55' viewBox='0 0 55 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M6.875 50.4166H48.125V18.3333H43.5417V13.7499H38.9583V18.3333H34.375V13.7499H38.9583V9.16658H34.375V4.58325H6.875V50.4166ZM11.4583 45.8332V9.16658H29.7917V22.9166H43.5417V45.8332H11.4583Z'
                                fill='#001514'
                            />
                        </svg>

                        <p>{text}</p>

                        <button type='button' onClick={openFileExplorer}>
                            Buscar archivos
                        </button>

                        {files.length > 0 && isMultiple ? (
                            <div className='images-preview' style={{ marginTop: 24 }}>
                                {files.map((file, index) => (
                                    <div key={index} className='image-container'>
                                        <img src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
                                        <button type='button' onClick={() => removeFile(index)}>
                                            Eliminar
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </>
                )}
            </article>
        </div>
    );
};

export default DragDrop;
