import { useNavigate } from "react-router-dom";
import DragDrop from "../../../Components/DragDrop/DragDrop";
import CreateInteractiveMap from "../../../Components/CreateInteractiveMap/CreateInteractiveMap";
import "./CrearEditarDesarrollo.scss";
import FeaturesCheckboxes from "./FeaturesCheckboxes";
import { uploadToS3, createDesarrollo, getAllServices } from "../../../Utils/endpoints";
import Swal from "sweetalert2";
// import AuxinteractiveMap from "./AuxinteractiveMap";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CrearDesarrollo = () => {
    const [imageFile, setImageFile] = useState();
    const [bannerFile, setBannerFile] = useState();
    const [galleryFiles, setGalleryFiles] = useState();
    const [documentFile, setDocumentFile] = useState();
    const [blueprintFile, setBlueprintFile] = useState();
    const [selectServices, setSelectServices] = useState([]);
    const [services, setServices] = useState([]);
    const [lotes, setLotes] = useState([]);
    let { id } = useParams();
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);

    const [descriptionValue, setDescriptionValue] = useState("");

    const getServices = async () => {
        const result = await getAllServices();
        setServices(result.data);
    };

    useEffect(() => {
        getServices();
    }, []);

    const createSubmit = async (e) => {
        e.preventDefault();
        // setLoading(true);

        const form = e.currentTarget;

        let imageFileUrl;
        let galleryFilesUrls = [];
        let documentFileUrl;
        let blueprintFileUrl;
        let bannerFileUrl;

        const cargarImagenes = async () => {
            const uploadPromises = [];

            if (imageFile) {
                const formData = new FormData();
                formData.append("file", imageFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            imageFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            if (galleryFiles?.length > 0) {
                for (let file of galleryFiles) {
                    const formData = new FormData();
                    formData.append("file", file);
                    uploadPromises.push(
                        uploadToS3(formData).then((response) => {
                            if (response.status === 200) {
                                galleryFilesUrls.push(response.data.photoUrl);
                            }
                        })
                    );
                }
            }

            if (documentFile) {
                const formData = new FormData();
                formData.append("file", documentFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            documentFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            if (blueprintFile) {
                const formData = new FormData();
                formData.append("file", blueprintFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            blueprintFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            if (bannerFile) {
                const formData = new FormData();
                formData.append("file", bannerFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            bannerFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            await Promise.all(uploadPromises);
        };

        await cargarImagenes();

        const dataBody = {
            name: form.elements.name.value,
            description: descriptionValue,
            province: form.elements.location.value,
            locality: form.elements.location.value,
            address: "",
            coordinates: form.elements.locationCoordinates.value,
            services: selectServices,
            cant_lots: form.elements.lotes.value,

            gallery: galleryFilesUrls,
            documentation: documentFileUrl,
            desarrollo_plane: blueprintFileUrl,
            logo: imageFileUrl,
            banner: bannerFileUrl,
        };

        createDesarrollo(dataBody).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Se actualizó con éxito",
                    confirmButtonText: "Listo",
                    confirmButtonColor: "#003049",
                    customClass: {
                        title: "my-custom-title-class",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/desarrollos");
                    }
                });
            }
        });
    };

    return (
        <section className='crear-editar-desarrollo'>
            {/* <h2>HOLA</h2>
            <AuxinteractiveMap/> */}
            <h1>{id ? "Editar" : "Crear"} Desarrollo</h1>

            <div className='section-header'>
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>
                    <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z'
                            fill='#A3A9B6'
                        />
                    </svg>
                    <Link to={"/desarrollos"}>Desarrollos</Link>
                    <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z'
                            fill='#A3A9B6'
                        />
                    </svg>
                    <p>{id ? "Editar" : "Crear"} Desarrollo</p>
                </article>
                <article className='actions-page'>
                    <button type='button' className='main-btn' onClick={() => document.querySelector("#submit-button").click()}>
                        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z'
                                fill='#fcf4e6'
                            />
                        </svg>
                        {id ? "Editar" : "Crear"} Desarrollo
                    </button>
                </article>
            </div>

            <form onSubmit={createSubmit} method='post' autoComplete='off'>
                <div className='form-holder'>
                    <article className='form-col'>
                        <div className='card-input'>
                            <h4>Logo del desarrollo</h4>
                            <DragDrop
                                files={imageFile}
                                setFiles={setImageFile}
                                text={"Arrastrá y soltá la imagen"}
                                accept={"image/png, image/jpeg, image/webp, image/svg+xml"}
                                name='logo'
                            />
                        </div>
                        <div className='card-input'>
                            <h4>Banner del desarrollo</h4>
                            <DragDrop
                                files={bannerFile}
                                setFiles={setBannerFile}
                                text={"Arrastrá y soltá la imagen"}
                                accept={"image/png, image/jpeg, image/webp, image/svg+xml"}
                                name='banner'
                            />
                        </div>
                        <div className='card-input'>
                            <h4>Gallería</h4>
                            <DragDrop
                                files={galleryFiles}
                                setFiles={setGalleryFiles}
                                isMultiple={true}
                                text={"Arrastrá y soltá las imágenes"}
                                accept={"image/png, image/jpeg, image/webp"}
                                name='galeria'
                            />
                        </div>
                        <div className='card-input'>
                            <h4>Documentación</h4>
                            <DragDrop
                                files={documentFile}
                                setFiles={setDocumentFile}
                                text={"Arrastrá y soltá el documento"}
                                accept={"application/pdf"}
                                name='documentacion'
                            />
                        </div>
                        <div className='card-input'>
                            <h4>Plano</h4>
                            <DragDrop
                                files={blueprintFile}
                                setFiles={setBlueprintFile}
                                text={"Arrastrá y soltá el plano"}
                                accept={"image/png, image/jpeg, image/webp, image/svg+xml"}
                                name='plano'
                            />
                        </div>
                    </article>
                    <article className='form-col'>
                        <div className='card-input'>
                            <h4>Información general</h4>

                            <div className='input-label'>
                                <label htmlFor='name'>Nombre del desarrollo</label>
                                <input type='text' name='name' id='name' placeholder='Nombre' required />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='location'>Ubicación</label>
                                <input type='text' name='location' id='location' placeholder='La Plata' required />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='locationCoordinates'>Ubicación (Coordenadas)</label>
                                <input
                                    type='text'
                                    name='locationCoordinates'
                                    id='locationCoordinates'
                                    placeholder="34°36'47'' S 58°22'63'' O"
                                    required
                                />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='lotes'>Cantidad de lotes</label>
                                <input type='number' name='lotes' id='lotes' placeholder='0' min={1} defaultValue={1} required />
                            </div>
                            <div className='input-label'>
                                <h4>Servicios</h4>
                                <FeaturesCheckboxes
                                    setSelectServices={setSelectServices}
                                    selectServices={selectServices}
                                    getServices={getServices}
                                    features={services}
                                />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='description'>Breve descripción</label>
                                <ReactQuill theme='snow' value={descriptionValue} onChange={setDescriptionValue} />
                            </div>

                            {!blueprintFile?.length < 1 && (
                                <CreateInteractiveMap imageFile={blueprintFile} lotes={lotes} setLotes={setLotes} />
                            )}
                        </div>
                    </article>
                </div>
                <button type='submit' id='submit-button' style={{ display: "none" }}></button>
            </form>
        </section>
    );
};

export default CrearDesarrollo;
