export const Icon_plus = ({ width = "14", height = "14", fill = "#FCF4E6" }) => {
    return (
        <svg width={width} height={height} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z'
                fill={fill}
            />
        </svg>
    );
};
