import React from 'react';
import './UserDetails.css';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CardUser from "./CardUser.jsx"
import { getAllUsersPagination } from "../../Utils/endpoints.js"

const Usuarios = () => {
    const [selectUser, setSelectUser] = useState()
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const getAllUsers = async (page) => {
        getAllUsersPagination({
            page,
            perPage: 30
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.usuarios)
                setTotalPages(res.data.totalPages)
            }
        })
    }

    useEffect(() => {
        getAllUsers(page)
    }, [page])

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage)
        }
    }

    return (
        <Container>
            <h1>Usuarios</h1>
            <div className="section-header"
                style={{
                    width: '100%',
                }}
            >
                <article className="navigate-page">
                    <Link to={'/'}>Dashboard</Link>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z" fill="#A3A9B6" />
                    </svg>
                    <p>Usuarios</p>
                </article>
                <article className="actions-page">
                    <Link to={'/usuarios/crear'} className='main-btn'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z" fill="#fcf4e6" />
                        </svg>
                        Agregar Usuario
                    </Link>
                </article>
            </div>
            <ScrollContainer>
                {users?.length > 0 ? (
                    users.map((user, index) => (
                        <CardUser
                            key={user.id} // Usar la id del usuario como key
                            data={user}
                        />
                    ))
                ) : (
                    <p>No hay usuarios.</p>
                )}
            </ScrollContainer>
            <Pagination>
                <Button disabled={page <= 1} onClick={() => setPage(page - 1)}>Anterior</Button>
                <PageInfo>Página {page} de {totalPages}</PageInfo>
                <Button disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)}>Siguiente</Button>
            </Pagination>
        </Container>
    )
};

const Container = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: space-between;
    justify-content: flex-start;
    padding: 0 24px 32px;
`

const ScrollContainer = styled.div`
    height:auto;
    width:-webkit-fill-available;
    display:flex;
    flex-direction:column;
    align-items:center;
    overflow:auto;
    max-height:50vh;
    background-color:#ffffff;
    border:solid 1.5px #e0e2e7;
    border-radius: 8px;
    min-height:60vh;
`

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-family: Inter;
        width: -webkit-fill-available;
`;

const Button = styled.button`
    background-color: #003366;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;

    &:disabled {
        background-color: #e0e2e7;
        cursor: not-allowed;
    }
`;

const PageInfo = styled.span`
    margin: 0 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
`;

export default Usuarios;
