import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import { ReactComponent as EyeIcon } from "./icons/eye.svg";
// import { ReactComponent as EditIcon } from "./icons/edit.svg";
// import { ReactComponent as TrashIcon } from "./icons/trash.svg";

const CardSubasta = ({ auction }) => {
  const navigate = useNavigate();

  const redirectToDetail = (id) => {
    navigate(`/subastas/subasta/${id}`);
  };

  const formatCurrency = (num) => {
    return num.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  return (
    <Card >
      <GridItem>
        <input style={{ cursor: "pointer" }} type="checkbox" />
      </GridItem>
      {/* <GridItem>
        <Value>Subasta #{auction.id}</Value>
        <SubText>{auction.project || "N/A"}</SubText>
      </GridItem> */}
      <GridItem>
        <StatusBadge status={auction.active}>{auction.active}</StatusBadge>
      </GridItem>

      <GridItem>
        <Value>{auction.lot.development.name || "N/A"}</Value>
      </GridItem>

      <GridItem>
        <Value>{auction.lot.number_lot}</Value>
      </GridItem>
      <GridItem>
        <Value>{formatCurrency(auction.last_bid)}</Value>
      </GridItem>
      <GridItem>
        <Value>{new Date(auction.start_date).toLocaleDateString()}</Value>
      </GridItem>
      <GridItem>
        <Value>{new Date(auction.time_limit).toLocaleDateString()}</Value>
      </GridItem>
      <GridItem>
        <IconButton onClick={() => redirectToDetail(auction.id)}>
          Ver Detalle
        </IconButton>
        <IconButton onClick={() => navigate(`/subastas/editar/${auction.id}`)}>
          {/* <EditIcon /> */}
        </IconButton>
        <IconButton>
          {/* <TrashIcon /> */}
        </IconButton>
      </GridItem>
    </Card>
  );
};

const Card = styled.div`
  display: grid;
   grid-template-columns:  12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
//   gap: 16px;
  padding: 16px;
  border-bottom: 1.5px solid #e0e2e7;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
      width: -webkit-fill-available;
      min-height: 80px;
  &:hover {
    background-color: #f1f2f3;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Value = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #1a1c21;
`;

const SubText = styled.span`
  font-family: Inter;
  font-size: 12px;
  color: #6c757d;
`;

const StatusBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  color: white;
  background-color: ${({ status }) => {
    switch (status) {
      case "Proximamente":
        return "#FFA500";
      case "Activa":
        return "#ccdf94";
      case "Finalizada":
        return "#f9bdbd";
      case "Pausada":
        return "#6b6b6b";
      case "Cancelada":
        return "#6b6b6b";
      default:
        return "#6c757d";
    }
  }};
  border-radius: 12px;
  font-size: 12px;
  font-family: Inter;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 8px;
  color: #007bff;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export default CardSubasta;
