import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { deactivateDevelopment } from "../../Utils/endpoints";

// const ListContainer = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
//     gap: 20px;
//     padding: 20px;
// `;

const Card = styled.div`
    display: grid;
    grid-template-columns: 27% 28% 24% 18%;
    padding: 16px;
    border-bottom: 1.5px solid #e0e2e7;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: -webkit-fill-available;
    min-height: 80px;
    align-content: center;
    align-items: center;
    justify-content: start;
    &:hover {
        background-color: #f1f2f3;
    }
`;

const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const CardTitle = styled.span`
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: #1a1c21;
`;

const CardImage = styled.img`
    width: 160px;
    height: 90px;
    object-fit: contain;
    margin-bottom: 10px;
`;

const Value = styled.span`
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: #1a1c21;
`;

const CardActions = styled.div`
    display: flex;
    gap: 10px;
    button,
    a {
        padding: 10px 15px;
        text-decoration: none;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    button {
        background-color: #f00;
    }
    a {
        background-color: #007bff;
    }
`;

// const IconButton = styled.button`
//     background: none;
//     border: none;
//     cursor: pointer;
//     padding: 0;
//     margin: 0 8px;
//     color: #007bff;
//     transition: color 0.3s ease-in-out;
//     &:hover {
//         color: #0056b3;
//         text-decoration: underline;
//     }
//     &:focus {
//         outline: none;
//     }
// `;

const CardDesarrollo = ({ desarrollo, page, getAllDesarrollos }) => {
    const handleDeactivate = async () => {
        await deactivateDevelopment(desarrollo.id, !desarrollo.active);
        getAllDesarrollos(page);
    };

    return (
        <Card>
            <GridItem>
                <CardTitle>{desarrollo?.name}</CardTitle>
            </GridItem>

            <GridItem>
                <CardImage src={desarrollo?.imageDevelopment?.logo} alt={desarrollo?.nombre} loading='lazy' decoding='async' />
            </GridItem>
            <GridItem>
                <Value>
                    <b>{desarrollo?.cant_lots}</b>
                </Value>
            </GridItem>

            <GridItem>
                <CardActions>
                    {/* <Link to={`/desarrollos/administrar-desarrollo/${desarrollo?.id}`}>Editar</Link> */}
                    <button type='button' className={desarrollo.active ? "btn-deactivate" : "btn-activate"} onClick={handleDeactivate}>
                        {desarrollo.active ? "Desactivar" : "Activar"}
                    </button>
                    <Link to={`/desarrollos/desarrollo/${desarrollo?.id}`}>Ver detalle</Link>
                </CardActions>
            </GridItem>
        </Card>
    );
};

export default CardDesarrollo;
