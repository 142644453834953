import { useState, useEffect } from "react";
import style from "./ContainerImage.module.scss";
import { Icon_close as IconClose, Icon_plus as IconPlus } from "../../svgs";

export const ContainerImage = ({ galleryImages, handlePopupAddImage, setUpdatedGallery }) => {
    const [galleryImage, setGalleryImage] = useState([]);

    useEffect(() => {
        setGalleryImage(galleryImages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryImages]);

    const handleDeleteImage = (i) => {
        let result = galleryImage.filter((element) => element !== i);
        setUpdatedGallery(result);
        setGalleryImage(result);
    };

    return (
        <>
            {galleryImage.map((e, i) => (
                <div className={style.container} key={i}>
                    <IconClose width='30' height='30' onClick={() => handleDeleteImage(e)} />

                    <img src={e} alt='' />
                </div>
            ))}

            <div className={style.container_add} onClick={handlePopupAddImage}>
                <IconPlus fill='#17223c' height='25' width='25' />
            </div>
        </>
    );
};
