import { Link } from 'react-router-dom';
import './Subastas.scss';
import styled from 'styled-components';
import { useState, useEffect, useRef } from "react";
import { getAllSubastasPagination } from "../../Utils/endpoints.js"
import CardSubasta from "./CardSubasta.jsx"
import HeaderListCard from "./HeaderListCard.jsx"

const Subastas = () => {
    const [selectUser, setSelectUser] = useState();
    const [statusFilter, setStatusFilter] = useState();
    const [subastas, setSubastas] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const scrollContainerRef = useRef(null);

    const getAllUsers = async (page) => {
        getAllSubastasPagination({
            page,
            perPage: 30,
            status:statusFilter
        }).then((res) => {
            if (res.status === 200) {
                setSubastas(res.data.auctions);
                setTotalPages(res.data.totalPages);
            }
        })
    }

    useEffect(() => {
        getAllUsers(page);
    }, [page, statusFilter]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
           
                scrollContainerRef.current.scrollTop = 0;
            
        }
    }

    return (
        <section className='subastas'>
            <h1>Subastas</h1>

            <div className="section-header">
                <article className="navigate-page">
                    <Link to={'/'}>Dashboard</Link>
                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z" fill="#A3A9B6" />
                    </svg>
                    <p>Subastas</p>
                </article>
                <article className="actions-page">
                    <Link to={'/subastas/crear'} className='main-btn'>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z" fill="#fcf4e6" />
                        </svg>
                        Crear Subasta
                    </Link>
                </article>
            </div>
            <HeaderListCard
             setStatusFilter={setStatusFilter}
             statusFilter={statusFilter}
             />
            <ScrollContainer ref={scrollContainerRef}>
                {subastas?.length > 0 ? (
                    subastas.map((subasta) => (
                        <CardSubasta
                            key={subasta.id}
                            auction={subasta}
                        />
                    ))
                ) : (
                    <p>No hay subastas.</p>
                )}
            </ScrollContainer>
            <Pagination>
                <Button disabled={page <= 1} onClick={() => handlePageChange(page - 1)}>Anterior</Button>
                <PageInfo>Página {page} de {totalPages}</PageInfo>
                <Button disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)}>Siguiente</Button>
            </Pagination>
        </section>
    );
}

const PageInfo = styled.span`
    margin: 0 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
`;

const ScrollContainer = styled.div`
    height:auto;
    width:-webkit-fill-available;
    display:flex;
    flex-direction:column;
    align-items:center;
    overflow:auto;
    max-height:70vh;
    background-color:#ffffff;
    border:solid 1.5px #e0e2e7;
    border-radius: 8px;
    min-height:70vh;
`;

const Button = styled.button`
    background-color: #003366;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;

    &:disabled {
        background-color: #e0e2e7;
        cursor: not-allowed;
    }
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-family: Inter;
    width: -webkit-fill-available;
`;

export default Subastas;
