import React from 'react';
import styled from 'styled-components';
import DropDown from "./DropDown";
const HeaderListCard = ({
  setStatusFilter,
  statusFilter
}) => {

  const statusOptions = [
    "Todos",
    "Activa",
    "Cancelada",
    "Proximamente",
    "Pausada",
    "Finalizada"
    // "En Seguimiento"
  ]
  return (
    <HeaderContainer>
              <HeaderItem>
        <Checkbox type="checkbox" />
        {/* <HeaderText>Subasta</HeaderText> */}
      </HeaderItem>

      <HeaderItem>
        {/* <HeaderText>Estado</HeaderText> */}
        <DropDown
        options={statusOptions}
        selectedOption={statusFilter}
        setSelectedOption={setStatusFilter}
        />
      </HeaderItem>

      
      <HeaderItem>
        <HeaderText>Proyecto</HeaderText>
      </HeaderItem>
      <HeaderItem>
        <HeaderText>Lote</HeaderText>
      </HeaderItem>
      <HeaderItem>
        <HeaderText>Oferta máxima U$D</HeaderText>
      </HeaderItem>
      <HeaderItem>
        <HeaderText>Fecha Inicio</HeaderText>
      </HeaderItem>
      <HeaderItem>
        <HeaderText>Fecha finalización</HeaderText>
      </HeaderItem>
      <HeaderItem>
        <HeaderText>Acción</HeaderText>
      </HeaderItem>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns:  12.5% 12.5% 12% 12.5% 12.5% 12.5% 12.5% 12.5%;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 10px 16px;
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;

export default HeaderListCard;
