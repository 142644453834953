import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './Dashboard.css';
import RadarChart from "../Components/RadarChart/RadarChart";

const dataDay = [
  { name: '12am', usuarios: 10 },
  { name: '1am', usuarios: 12 },
  // Agrega más datos para cada hora del día
];

const dataWeek = [
  { name: 'Lun', usuarios: 400 },
  { name: 'Mar', usuarios: 300 },
  { name: 'Mié', usuarios: 200 },
  { name: 'Jue', usuarios: 278 },
  { name: 'Vie', usuarios: 189 },
  { name: 'Sáb', usuarios: 239 },
  { name: 'Dom', usuarios: 349 },
];

const dataMonth = [
  { name: '1', usuarios: 400 },
  { name: '2', usuarios: 300 },
  { name: '3', usuarios: 200 },
  { name: '4', usuarios: 278 },
  { name: '5', usuarios: 189 },
  { name: '6', usuarios: 239 },
  { name: '7', usuarios: 349 },
  { name: '8', usuarios: 200 },
  { name: '9', usuarios: 278 },
  { name: '10', usuarios: 189 },
  { name: '11', usuarios: 239 },
  { name: '12', usuarios: 349 },
  { name: '13', usuarios: 200 },
  { name: '14', usuarios: 278 },
  { name: '15', usuarios: 189 },
  { name: '16', usuarios: 239 },
  { name: '17', usuarios: 349 },
  { name: '18', usuarios: 200 },
  { name: '19', usuarios: 278 },
  { name: '20', usuarios: 189 },
  { name: '21', usuarios: 239 },
  { name: '22', usuarios: 349 },
  { name: '23', usuarios: 200 },
  { name: '24', usuarios: 278 },
  { name: '25', usuarios: 189 },
  { name: '26', usuarios: 239 },
  { name: '27', usuarios: 349 },
  { name: '28', usuarios: 200 },
  { name: '29', usuarios: 278 },
  { name: '30', usuarios: 189 },
  { name: '31', usuarios: 239 },
];

const dataYear = [
  { name: 'Ene', usuarios: 400 },
  { name: 'Feb', usuarios: 300 },
  { name: 'Mar', usuarios: 200 },
  { name: 'Abr', usuarios: 278 },
  { name: 'May', usuarios: 189 },
  { name: 'Jun', usuarios: 239 },
  { name: 'Jul', usuarios: 349 },
  { name: 'Ago', usuarios: 200 },
  { name: 'Sep', usuarios: 278 },
  { name: 'Oct', usuarios: 189 },
  { name: 'Nov', usuarios: 239 },
  { name: 'Dic', usuarios: 349 },
];

const Dashboard = () => {
  const [data, setData] = useState(dataYear);
  const [filter, setFilter] = useState('Año');

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    switch (newFilter) {
      case 'Día':
        setData(dataDay);
        break;
      case 'Semana':
        setData(dataWeek);
        break;
      case 'Mes':
        setData(dataMonth);
        break;
      case 'Año':
        setData(dataYear);
        break;
      default:
        setData(dataYear);
    }
  };

  return (
    <div className="Home">
      <div className="top-cards">
        <div className="card">
          <h3>Saldo Acumulado</h3>
          <p>$75,500 <span className="increase">+10%</span></p>
        </div>
        <div className="card">
          <h3>Subastas Finalizadas</h3>
          <p>1,200 <span className="increase">+15%</span></p>
        </div>
        <div className="card">
          <h3>Pujas Totales</h3>
          <p>31,500 <span className="increase">+5%</span></p>
        </div>
        <div className="card">
          <h3>Usuarios Activos</h3>
          <p>24,500 <span className="decrease">-5%</span></p>
        </div>
      </div>
      <div className="bottom-section">
        <RadarChart />
        <div className="statistics">
          <h3>Estadísticas de Usuarios Registrados</h3>
          <div className="filter-buttons">
            <button onClick={() => handleFilterChange('Día')}>Día</button>
            <button onClick={() => handleFilterChange('Semana')}>Semana</button>
            <button onClick={() => handleFilterChange('Mes')}>Mes</button>
            <button onClick={() => handleFilterChange('Año')}>Año</button>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="usuarios" stroke="#14213d" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
