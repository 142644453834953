import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './Components/Sidebar/Sidebar';
import NavBarHorizontal from "./Components/NavBarHorizontal/NavBarHorizontal";

// Pages
import DashboardAux from "./Pages/DashboardAux";
import Desarrollos from './Pages/desarrollos/Desarrollos';
import CrearDesarrollo from './Pages/desarrollos/crear-editar/CrearDesarrollo';
import Desarrollo from './Pages/desarrollos/desarrollo/Desarrollo';
import Lote from './Pages/desarrollos/desarrollo/lote/Lote';
import UserDetails from "./Pages/usuarios/UserDetails";
import Usuarios from "./Pages/usuarios/Usuarios";
import CreateUser from "./Pages/usuarios/CreateUser";
import Tareas from './Pages/tareas/Tareas';
import Subastas from './Pages/subastas/Subastas';
import CreateSubasta from "./Pages/subastas/CreateSubasta";
import DetalleSubasta from "./Pages/subastas/DetalleSubasta";
import Login from './Components/Login/Login'; // Ajusta la ruta según tu estructura de proyecto


const App = () => {


  return (

    <Routes>
            <Route path='/login' element={<Login />} />
   
      <Route path='/' element={ <DashboardAux /> } />
      <Route path='/usuarios' element={ <DashboardAux /> } />
      <Route path='/usuarios/crear' element={ <DashboardAux /> } />
      <Route path='/usuarios/:id' element={ <DashboardAux /> } />
      <Route path='/desarrollos' element={ <DashboardAux /> } />
      <Route path='/desarrollos/administrar-desarrollo' element={ <DashboardAux /> } />
      <Route path='/desarrollos/administrar-desarrollo/:id' element={ <DashboardAux /> } />
      <Route path='/desarrollos/desarrollo/:id' element={ <DashboardAux /> } />
      <Route path='/desarrollos/desarrollo/:id/administrar-lote' element={ <DashboardAux /> } />
      <Route path='/desarrollos/desarrollo/:id/administrar-lote/:id2' element={ <DashboardAux /> } />
      <Route path='/tareas' element={ <DashboardAux /> } />
      <Route path='/subastas' element={ <DashboardAux /> } />
      <Route path='/subastas/crear' element={ <DashboardAux /> } />
      <Route path='/subastas/subasta/:id' element={ <DashboardAux /> } />
      <Route path='/subastas/editar-subasta/:id' element={ <DashboardAux /> } />
    </Routes>

  );
}

export default App;
